<template>
    <div class="row justify-center mt-2 px-2" style="margin-bottom: 88px">
        <div class="xs12">
            <DxValidationGroup ref="formValidation">
                <div class="row font-italic font-medium color-warning mt-1">
                    * Lưu ý: Những thông tin có dấu (*) là những thông tin bắt buộc phải
                    điền!
                </div>
                <div class="mt-2" v-if="$route.query.IdLaiXe && LayChiTietThanhCong">
                    <p class="text-style" v-if="ThongTinLaiXe.DaCoTaiKhoan == true">
                        <span class="color-success"> Lái xe đã có tài khoản! </span>
                        <span
                            class="text-color"
                            @click="HienThiPopupDatMatKhauHoacTaoTaiKhoan('DatMatKhau')"
                            >Đặt lại mật khẩu?</span
                        >
                    </p>
                    <p class="text-style" v-else>
                        <span class="color-warning"> Lái xe chưa có tài khoản! </span>

                        <span
                            class="text-color"
                            @click="HienThiPopupDatMatKhauHoacTaoTaiKhoan('TaoTaiKhoan')"
                            >Tạo tài khoản?</span
                        >
                    </p>
                </div>
                <div class="row mt-2" v-if="action && action == 'Add'">
                    <DxCheckBox
                        v-model="CheckTaoTaiKhoan"
                        text="Tạo tài khoản lái xe theo số điện thoại"
                    />
                </div>

                <div class="row mt-2">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLaiXe.HoTen"
                            label="Họ tên (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Họ tên không được bỏ trống!" />
                                <DxStringLengthRule
                                    :max="128"
                                    message="Họ tên không được vượt quá 128 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxTextBox
                            v-model="ThongTinLaiXe.SoDienThoai"
                            label="Số điện thoại (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :onValueChanged="onValueChangedSoDienThoai"
                            mode="tel"
                        >
                            <DxValidator ref="refValidateSoDienThoai">
                                <DxRequiredRule
                                    message="Số điện thoại không được bỏ trống!"
                                />
                                <DxStringLengthRule
                                    :max="13"
                                    message="Số điện thoại không được vượt quá 13 ký tự!"
                                />
                                <DxStringLengthRule
                                    :min="10"
                                    message="Số điện thoại không được nhỏ hơn 10 ký tự!"
                                />
                                <DxPatternRule
                                    :pattern="phonePattern"
                                    message="Số điện thoại không đúng định dạng!"
                                />
                                <DxNumericRule message="Số điện thoại phải là số!" />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs7 mr-2">
                        <DxTextBox
                            v-model="ThongTinLaiXe.SoGPLX"
                            label="Số GPLX (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <DxValidator>
                                <DxRequiredRule message="Số GPLX không được bỏ trống!" />
                                <DxStringLengthRule
                                    :max="50"
                                    message="Số GPLX không được vượt quá 50 ký tự!"
                                />
                            </DxValidator>
                        </DxTextBox>
                    </div>
                    <div class="xs5 ml-2">
                        <DxSelectBox
                            v-model:value="ThongTinLaiXe.HangGPLX"
                            label="Hạng GPLX (*)"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :data-source="DanhSachHangGPLX"
                            display-expr="HangGPLX"
                            value-expr="IdHangGP"
                            :search-enabled="true"
                            :showClearButton="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Hạng GPLX không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxDateBox
                            v-model="ThongTinLaiXe.HanGPLX"
                            label="Hạn hiệu lực GPLX (*)"
                            labelMode="floating"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    message="Hạn hiệu lực GPLX không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxDateBox
                            v-model="ThongTinLaiXe.NgayBatDauHD"
                            label="Ngày bắt đầu hợp đồng (*)"
                            labelMode="floating"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    message="Ngày bắt đầu hợp đồng không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                                <DxRangeRule
                                    :max="ThongTinLaiXe.NgayKetThucHD || new Date()"
                                    message="Ngày bắt đầu hợp đồng phải <= Ngày kết thúc hợp đồng!"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="xs12">
                        <DxDateBox
                            v-model="ThongTinLaiXe.NgayKetThucHD"
                            label="Ngày kết thúc hợp đồng (*)"
                            labelMode="floating"
                            displayFormat="dd/MM/yyyy"
                            :use-mask-behavior="true"
                            dropDownButtonTemplate="customIcon"
                            styling-mode="underlined"
                            validationMessageMode="always"
                        >
                            <template #customIcon="{}">
                                <DxButton icon="mdi mdi-calendar" />
                            </template>
                            <DxValidator>
                                <DxRequiredRule
                                    message="Ngày kết thúc hợp đồng không được bỏ trống!"
                                />
                                <DxRangeRule
                                    :max="MaxDate"
                                    :message="$i18n.t('DefaultString.MaxDate')"
                                />
                                <DxRangeRule
                                    :min="MinDate"
                                    :message="$i18n.t('DefaultString.MinDate')"
                                />
                                <DxRangeRule
                                    :min="ThongTinLaiXe.NgayBatDauHD || new Date()"
                                    message="Ngày kết thúc >= Ngày bắt đầu"
                                />
                            </DxValidator>
                        </DxDateBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="ThongTinLaiXe.TinhThanhPho"
                            label="Tỉnh/Thành phố (*)"
                            :data-source="DanhSachTinhThanh"
                            display-expr="TenTinh"
                            value-expr="ID_Tinh"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :showClearButton="true"
                            :onItemClick="chonTinhThanh"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Tỉnh/Thành phố không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div class="row mt-4">
                    <div class="xs12">
                        <DxSelectBox
                            v-model="ThongTinLaiXe.QuanHuyen"
                            label="Quận/Huyện (*)"
                            :data-source="DanhSachQuanHuyen"
                            display-expr="TenHuyen"
                            value-expr="ID_Huyen"
                            labelMode="floating"
                            styling-mode="underlined"
                            validationMessageMode="always"
                            :search-enabled="true"
                            :showClearButton="true"
                        >
                            <DxValidator>
                                <DxRequiredRule
                                    message="Quận/Huyện không được bỏ trống!"
                                />
                            </DxValidator>
                        </DxSelectBox>
                    </div>
                </div>

                <div
                    class="row transition mt-4"
                    :style="`max-height: ${
                        openThongTinMoRong ? `1000px` : `32px`
                    }; overflow: hidden`"
                >
                    <div class="xs12">
                        <div
                            class="row align-center font-16 font-medium"
                            @click="openThongTinMoRong = !openThongTinMoRong"
                        >
                            <i
                                class="mdi mdi-menu-down-outline transition"
                                :style="`transform: rotate( ${
                                    openThongTinMoRong ? `180` : `0`
                                }deg);`"
                            ></i>
                            Thông tin mở rộng
                        </div>
                        <div class="row mt-1">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="ThongTinLaiXe.CCCD"
                                    label="Số CMND/CCCD"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Số CMND/CCCD không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="xs12">
                                <DxTagBox
                                    v-model:value="ThongTinLaiXe.NhaXe"
                                    label="Nhà xe"
                                    :data-source="DanhSachNhaXeSelect"
                                    display-expr="TenNhaXe"
                                    value-expr="IdNhaXe"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                    :search-enabled="true"
                                    :showDropDownButton="true"
                                >
                                    <!-- <DxValidator>
                            <DxRequiredRule
                                message="Nhà xe không được bỏ trống!"
                            />
                        </DxValidator> -->
                                </DxTagBox>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="ThongTinLaiXe.SerialNumber"
                                    label="Số serial"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                    validationMessageMode="always"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="512"
                                            message="Số serial không được vượt quá 512 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="xs12">
                                <DxTextBox
                                    v-model="ThongTinLaiXe.DiaChiCuThe"
                                    label="Địa chỉ cụ thể"
                                    labelMode="floating"
                                    styling-mode="underlined"
                                >
                                    <DxValidator>
                                        <DxStringLengthRule
                                            :max="128"
                                            message="Địa chỉ cụ thể không được vượt quá 128 ký tự!"
                                        />
                                    </DxValidator>
                                </DxTextBox>
                            </div>
                        </div>
                    </div>
                </div>
            </DxValidationGroup>
        </div>
    </div>

    <div class="button-bottom row justify-space-between align-center">
        <DxButton text="Hủy" type="danger" styling-mode="text" @click="$router.go(-1)" />
        <DxButton
            text="Lưu"
            type="default"
            styling-mode="contained"
            v-if="
                action == 'Add'
                    ? $Helper.KiemTraQuyen(
                          $t('QuyenLenhDienTu.LENHDIENTU'),
                          $t('QuyenLenhDienTu.ThemLaiXe'),
                      )
                    : $Helper.KiemTraQuyen(
                          $t('QuyenLenhDienTu.LENHDIENTU'),
                          $t('QuyenLenhDienTu.SuaLaiXe'),
                      ) && LayChiTietThanhCong
            "
            @click="LuuThongTin()"
        />
    </div>

    <PopupVue
        height="auto"
        v-if="ifPopupThongTinTaiKhoanLaiXe"
        v-model:dialog="PopupThongTinTaiKhoanLaiXe"
        title=""
        buttonTextLeft="Hủy"
        buttonTextRight="Xác nhận"
        class="popup-padding-16"
        :useButtonRight="false"
        :useButtonLeft="false"
        heightScrollView="auto"
    >
        <template #content>
            <PopupThongTinTaiKhoanLaiXeVue
                :title="titlePopup"
                :TenTaiKhoan="ThongTinLaiXe.SoDienThoai"
                @XacNhanDatMatKhau="PhanLoaiHanhDong"
                @Huy="HuyPopupTaiKhoan"
            />
        </template>
    </PopupVue>
</template>

<script>
import { mapState } from "vuex";
import {
    DxTextBox,
    DxSelectBox,
    DxDateBox,
    DxButton,
    DxValidator,
    DxValidationGroup,
    DxNumberBox,
    DxTagBox,
    DxCheckBox,
} from "devextreme-vue";
import {
    DxNumericRule,
    DxPatternRule,
    DxRequiredRule,
    DxStringLengthRule,
    DxRangeRule,
} from "devextreme-vue/data-grid";
import PopupThongTinTaiKhoanLaiXeVue from "./PopupThongTinTaiKhoanLaiXe.vue";
import PopupVue from "../../../../../components/_Common/Popup.vue";
const date = new Date();
export default {
    components: {
        DxTextBox,
        DxSelectBox,
        DxRequiredRule,
        DxValidator,
        DxStringLengthRule,
        DxNumericRule,
        DxPatternRule,
        DxValidationGroup,
        DxDateBox,
        DxButton,
        DxNumberBox,
        DxRangeRule,
        DxTagBox,
        DxCheckBox,
        PopupThongTinTaiKhoanLaiXeVue,
        PopupVue,
    },
    props: {
        action: { type: String, default: "" },
        // isValidSoDienThoai: { type: Boolean, default: true },
        TaiKhoanDaTonTai: { type: Boolean, default: true },
        LayChiTietThanhCong: { type: Boolean, default: false },
    },
    data() {
        return {
            phonePattern: /^[+]{0,1}[0-9]+$/,
            year: date.getFullYear(),
            MaxDate: new Date(this.$i18n.t("DefaultNumBer.MaxDate")),
            MinDate: new Date(this.$i18n.t("DefaultNumBer.MinDate")),
            resetQuanHuyen: false,
            openThongTinMoRong: false,
            TaiKhoanDaTonTai: true,
            TaoTaiKhoanSua: false,
            SuaTaiKhoan: false,
            titlePopup: "Tạo mới tài khoản",
            ifPopupThongTinTaiKhoanLaiXe: false,
            PopupThongTinTaiKhoanLaiXe: false,
            keyPopup: "TaoTaiKhoan",
        };
    },
    computed: {
        ...mapState({
            DanhSachLoaiBen: (state) => state.ThongTinBenXe.DanhSachLoaiBen,
            DanhSachTinhThanh: (state) => state.ThongTinHeThong.DanhSachTinhThanh,
            DanhSachQuanHuyen: (state) => state.ThongTinHeThong.DanhSachQuanHuyen,
            DanhSachHangGPLX: (state) => state.ThongTinLaiXe.DanhSachHangGPLX,
            ThongTinLaiXe: (state) => state.ThongTinLaiXe.ThongTinLaiXe,
            DanhSachNhaXeSelect: (state) => state.ThongTinLaiXe.DanhSachNhaXeSelect,
        }),
        CheckTaoTaiKhoan: {
            get() {
                return this.$store.state.TaiKhoanLaiXe.CheckTaoTaiKhoan;
            },
            set(value) {
                this.$store.commit("TaiKhoanLaiXe/Set", {
                    key: "CheckTaoTaiKhoan",
                    data: value,
                });
            },
        },
    },
    watch: {
        "ThongTinLaiXe.TinhThanhPho": {
            handler: function () {
                this.getQuanHuyen();
            },
        },
        PopupThongTinTaiKhoanLaiXe() {
            if (!this.PopupThongTinTaiKhoanLaiXe) {
                this.NgatDOMPopup("ifPopupThongTinTaiKhoanLaiXe");
            }
        },
    },
    methods: {
        showPopup(name) {
            this["if" + name] = true;
            this[name] = true;
        },
        NgatDOMPopup(key) {
            setTimeout(() => {
                this[key] = false;
            }, 300);
        },
        onValueChangedSoDienThoai() {
            this.isValidSoDienThoai = this.$refs.refValidateSoDienThoai
                ? this.$refs.refValidateSoDienThoai.instance.validate().isValid
                : true;
        },
        async LayDanhSachNhaXe() {
            try {
                let rs = await this.$store.dispatch("ThongTinLaiXe/LayDanhSachNhaXe");

                if (!rs.status) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        `Lấy danh sách nhà xe thất bại! ${rs.message}`,
                    );
                }
            } catch (error) {
                console.log(error);
            }
        },
        chonTinhThanh() {
            this.resetQuanHuyen = true;
        },
        getQuanHuyen() {
            if (this.resetQuanHuyen) {
                this.ThongTinLaiXe.QuanHuyen = null;
                this.resetQuanHuyen = false;
            }
            if (this.ThongTinLaiXe.TinhThanhPho) {
                this.getDanhSachHuyen();
            } else {
                this.$store.commit("ThongTinBenXe/Set", {
                    key: "DanhSachQuanHuyen",
                    data: [],
                });
            }
        },
        async getDanhSachHuyen() {
            try {
                let rs = await this.$store.dispatch(
                    "ThongTinHeThong/Get_DanhSachQuanHuyen",
                    this.ThongTinLaiXe.TinhThanhPho,
                );
                if (rs.length == 0) {
                    this.$Helper.ThongBaoToast(
                        "error",
                        `Không có Quận/Huyện thuộc tỉnh này!`,
                    );
                } else {
                    if (rs[0].TenHuyen == this.$t("string.KhongXacDinh")) {
                        this.ThongTinLaiXe.QuanHuyen = rs[0].ID_Huyen;
                    }
                }
            } catch (ex) {
                this.$Helper.ThongBaoToast(
                    "error",
                    `Lấy danh sách quận huyện bị lỗi! Lỗi hệ thống client.`,
                );
            }
        },
        async LuuThongTin() {
            let validate = this.$refs.formValidation.instance.validate();

            if (validate.isValid) {
                this.$emit("LuuThongTin");
            } else {
                return this.$Helper.autoFocusError(validate);
            }
        },
        HienThiPopupDatMatKhauHoacTaoTaiKhoan(key) {
            this.keyPopup = key;
            if (key == "DatMatKhau") {
                this.titlePopup = "Đặt lại mật khẩu";
            } else {
                this.titlePopup = "Tạo mới tài khoản";
            }
            this.showPopup("PopupThongTinTaiKhoanLaiXe");
        },
        PhanLoaiHanhDong(MatKhau) {
            if (this.keyPopup == "DatMatKhau") {
                this.DatLaiMatKhau(MatKhau);
            } else {
                this.TaoTaiKhoan(MatKhau);
            }
        },
        async DatLaiMatKhau(MatKhau) {
            try {
                this.$startLoading;
                let query = {
                    TenTaiKhoan: this.ThongTinLaiXe.SoDienThoai,
                    MatKhau: MatKhau,
                };
                let rs = await this.$store.dispatch(
                    "TaiKhoanLaiXe/DatLaiMatKhauTaiKhoan",
                    query,
                );
                if (rs.status == false) {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Đặt lại mật khẩu thất bại!",
                        message: rs.message,
                    });
                } else {
                    this.$Helper.ThongBaoToast("success", `Đặt lại mật khẩu thành công!`);
                    this.PopupThongTinTaiKhoanLaiXe = false;
                }
            } catch (ex) {
                console.log("🚀 ~ ex:", ex);
                this.$Helper.ThongBaoPopup({
                    message_title: "Đặt lại mật khẩu thất bại!",
                    message: "Lỗi hệ thống client.",
                });
            } finally {
                this.$stopLoading;
            }
        },
        async TaoTaiKhoan(MatKhau) {
            let query = {
                TenTaiKhoan: this.ThongTinLaiXe.SoDienThoai,
                HoTen: this.ThongTinLaiXe.HoTen,
                MatKhau: MatKhau,
            };
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch("TaiKhoanLaiXe/ThemTaiKhoan", query);
                if (rs.status == false) {
                    this.$Helper.ThongBaoPopup({
                        message_title: "Tạo tài khoản lái xe thất bại!",
                        message: rs.message,
                    });
                } else {
                    this.PopupThongTinTaiKhoanLaiXe = false;
                    this.$Helper.ThongBaoToast(
                        "success",
                        `Tạo tài khoản lái xe thành công!`,
                    );
                    if (this.action == "Add") {
                        this.$router.go(-1);
                        return;
                    } else {
                        this.$emit("getData");
                    }
                }
            } catch (ex) {
                this.$Helper.ThongBaoPopup({
                    message_title: "Tạo tài khoản lái xe thất bại!",
                    message: "Lỗi hệ thống client.",
                });
            } finally {
                this.$stopLoading;
            }
        },
        HuyPopupTaiKhoan() {
            this.PopupThongTinTaiKhoanLaiXe = false;
            console.log(
                "🚀 ~ file: ThongTinLaiXe.vue:677 ~ HuyPopupTaiKhoan ~ this.action:",
                this.action,
            );
            if (this.action == "Add") {
                this.$router.go(-1);
            }
        },
    },
    created() {
        this.LayDanhSachNhaXe();
    },
};
</script>

<style scoped>
.title {
    font-weight: 500;
    font-size: 20px;
}
.text-style {
    font-weight: 600;
}
.text-color {
    color: #03a9f4;
    cursor: pointer;
    text-decoration: underline;
}
:deep(.dx-tagbox.dx-texteditor.dx-editor-underlined::after) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}
:deep(.dx-tagbox.dx-texteditor.dx-state-active::before),
:deep(.dx-tagbox.dx-texteditor.dx-state-focused::before) {
    border-bottom: 2px solid #03a9f4 !important;
    transform: scale(1) !important;
    transition: transform 0.6s cubic-bezier(0.4, 0, 0.02, 1) !important;
}
</style>
